import { Trans, useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';

function SpanishPhilosophyHome() {
    const { t } = useTranslation();
    return(
        <div className="mt-[3rem] lg:mt-[9.5rem] text-center">
            <p className="px-[2.625rem] font-poppins font-bold text-[6.667vw] lg:text-[2rem]">{t('home.philosophy.header')}</p>
            <img className="hidden max-w-[837px] lg:inline-block mt-[1rem]" src="https://d3hde7ibm2i7sg.cloudfront.net/CSpanishPhilosophyDesktopTablet.png" alt="hrcfa-spanish-philosophy"/>
            <img className="lg:hidden aspect-[480/382] inline-block mt-[1.75rem]" src="https://d3hde7ibm2i7sg.cloudfront.net/CSpanishPhilosophyM.png" alt="hrcfa-spanish-philosophy"/>
            <p className="px-[2.625rem] lg:px-0 lg:mx-auto lg:max-w-[63rem] mt-[1.5rem] font-inter text-[4.167vw] lg:text-[1.25rem]">{t('home.philosophy.subtitle')}</p>
            <div className="px-[2.625rem]">
            <Link to="/about-us">
            <button className="w-full text-[4.167vw] lg:text-[1.25rem] mt-[2.563rem] lg:mt-[3.375rem] lg:max-w-[24.688rem] h-[3.75rem] bg-hrcRed rounded-[0.188rem] font-medium font-inter">{t('home.philosophy.button')}</button>
            </Link>
            </div>
        </div>
    )
}

export default SpanishPhilosophyHome;