import { useTranslation } from "react-i18next";

function ProgramSectionHomebase() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="lg:hidden mt-[7.5rem] text-center mx-[2.625rem]">
        <p className="text-[6.667vw] lg:text-[2rem] font-bold font-poppins">
          {t("programs.homebase.header")}
        </p>
        <img
          className="mt-[2.375rem] inline mx-auto"
          src="https://d3hde7ibm2i7sg.cloudfront.net/CHomebaseM.png"
          alt="Stadion Viyata Jales Yudha"
        />
        <p className="mt-[5vw] font-inter font-bold text-[5vw] lg:text-[1.5rem]">
          {t("programs.homebase.location_1")}
        </p>
        <p className="mt-[1.375rem] font-inter text-[4.167vw] lg:text-[1.25rem]">
          {t("programs.homebase.location_2")}
        </p>
      </div>
      <div className="hidden lg:inline-block lg:mt-[14.3rem] lg:mx-auto lg:flex lg:flex-row lg:gap-[5rem] lg:justify-center lg:items-center">
        <div className="">
          <p className="text-[6.667vw] lg:text-[2rem] font-bold font-poppins">
            {t("programs.homebase.header")}
          </p>
          <p className="mt-[5vw] font-inter font-bold text-[5vw] lg:text-[1.5rem]">
            {t("programs.homebase.location_1")}
          </p>
          <p className="mt-[1.375rem] font-inter text-[4.167vw] lg:text-[1.25rem]">
            {t("programs.homebase.location_2")}
          </p>
        </div>
        <img
          className="lg:w-max-[41.8rem]"
          src="https://d3hde7ibm2i7sg.cloudfront.net/StadiumDesktop.png"
          alt="Stadion Viyata Jales Yudha"
        />
      </div>
    </div>
  );
}

export default ProgramSectionHomebase;
