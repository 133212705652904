import OCSectionVideoGrid from "../sections/online-course-page-sections/OCSectionVideoGrid";

function OnlineCourse() {
    return(
        <div>
            <OCSectionVideoGrid />
        </div>
    )
}

export default OnlineCourse;