import Hero from '../component/Hero.js';
import { useState, useEffect } from "react";
import FeatureSectionHome from '../sections/FeatureSectionHome.js';
import JourneySectionHome from '../sections/JourneySectionHome.js';
import CareerSectionHome from '../sections/CareerSectionHome.js';
import SuccessPlayerHome from '../sections/SuccessPlayerHome.js';
import CoachesSectionHome from '../sections/CoachesSectionHome.js';
import TestimonialHome from '../sections/TestimonialHome.js';
import CourseSectionHome from '../sections/CourseSectionHome.js';
import HeroMobile from '../component/HeroMobile.js';
import CoachesSectionHomeM from '../sections/CoachesSectionHomeM.js';
import SpanishPhilosophyHome from '../sections/SpanishPhilosophyHome.js';

function Home() {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1000);

    const updateMedia = () => {
      setDesktop(window.innerWidth > 1000);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
    return(
        <div>
            {isDesktop ? <Hero /> : <HeroMobile />}
            <SpanishPhilosophyHome />
            <JourneySectionHome />
            <FeatureSectionHome />
            <CareerSectionHome />
            <SuccessPlayerHome />
            {isDesktop ? <CoachesSectionHome /> : <CoachesSectionHomeM />}
            <TestimonialHome />
            <CourseSectionHome />
        </div>
    )
}

export default Home;