import { useState, useEffect } from "react";
import AboutSectionAcademyPhilosophy from "../sections/about-us-page-sections/AboutSectionAcademyPhilosophy";
import AboutSectionCoaches from "../sections/about-us-page-sections/AboutSectionCoaches";
import AboutSectionFounder from "../sections/about-us-page-sections/AboutSectionFounder";
import AboutSectionSupportingStaff from "../sections/about-us-page-sections/AboutSectionSupportingStaff";
import AboutSectionSupportingStaffDesktop from "../sections/about-us-page-sections/AboutSectionSupportingStaffDesktop";
import AboutSectionVisionMission from "../sections/about-us-page-sections/AboutSectionVisionMission";


function About() {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1000);

    const updateMedia = () => {
      setDesktop(window.innerWidth > 1000);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
    return(
        <div>
            <AboutSectionAcademyPhilosophy />
            <AboutSectionVisionMission />
            <AboutSectionFounder />
        </div>
    )
}

export default About;