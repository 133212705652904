function VideoContainer(props) {
    return(
        <div className="video-responsive aspect-[395/221.924] w-[100%] lg:max-w-[66.5rem] h-[100%]">
            <iframe
            className="w-[100%] h-[100%] lg:max-w-[66.5rem]"
            src={`https://www.youtube.com/embed/${props.embedId}`} 
            allow="accelerometer"
            title="Embed Youtube Video" />
        </div>
    )
}

export default VideoContainer;