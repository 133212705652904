import founderImg from '../../svg-assets/about-page-assets/Group3.png';
import { useTranslation } from 'react-i18next';

function AboutSectionFounder() {
    const { t } = useTranslation();
    return(
        <div>
        <div className="lg:hidden mt-[1rem] px-[2.625rem] text-center">
            <div>
                <p className="font-bold font-poppins text-[6.667vw] lg:text-[2rem]">{t('about.founder_header')}</p>
                <img className="inline-block founder-background" src={founderImg} />
                <p className="font-inter font-bold text-[5vw] lg:text-[1.5rem]">{t('about.founder_name')}</p>
            </div>
            <div className="mt-[1.125rem] text-[4.167vw] lg:text-[1.25rem] text-left font-inter">
                <p className="">{t('about.founder_description')}</p>
                <ul className="mt-[2.875rem]">
                    <li>{t('about.point_1')}</li>
                    <li>{t('about.point_2')}</li>
                    <li>{t('about.point_3')}</li>
                    <li>{t('about.point_4')}</li>
                    <li>{t('about.point_5')}</li>
                    <li>{t('about.point_6')}</li>
                </ul>
            </div>
        </div>
        <div className="hidden lg:block h-[100vh] mt-[5rem] mb-[10rem] relative founder-background-desktop">
       <div className="pl-[14rem] absolute bottom-[8.6rem]">
       <div className="pt-[4rem] lg:max-w-[34.75rem]">
                <p className="font-bold font-poppins text-[6.667vw] lg:text-[2rem]">{t('about.founder_header')}</p>
                <p className="font-inter font-bold text-[5vw] lg:text-[1.5rem]">{t('about.founder_name')}</p>
            </div>
            <div className="mt-[1.125rem] text-[4.167vw] lg:text-[1.25rem] text-left font-inter lg:max-w-[34.75rem]">
                <p className="">{t('about.founder_description')}</p>
                <ul className="mt-[2.875rem]">
                    <li>{t('about.point_1')}</li>
                    <li>{t('about.point_2')}</li>
                    <li>{t('about.point_3')}</li>
                    <li>{t('about.point_4')}</li>
                    <li>{t('about.point_5')}</li>
                    <li>{t('about.point_6')}</li>
                </ul>
            </div>
            </div>
        </div>
        </div>
    )
}

export default AboutSectionFounder;