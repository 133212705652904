import { useState, useEffect } from "react";
import Hero from "../component/Hero";
import HeroCareer from "../component/HeroCareer";
import CareerCenterCTA from "../sections/career-center-page-sections/CareerSectionCTA";
import CareerSectionGrids from "../sections/career-center-page-sections/CareerSectionGrids";
// import CareerSectionHero from "../sections/career-center-page-sections/CareerSectionHero";
import CareerSectionSuccessPlayer from "../sections/career-center-page-sections/CareerSectionSuccessPlayer";
import HeroCareerMobile from "../component/HeroCareerMobile";

function Career() {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1000);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1000);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
      });
    
    return(
        <div>
            {isDesktop ? <HeroCareer /> : <HeroCareerMobile />}
            <CareerSectionGrids />
            <CareerSectionSuccessPlayer />
            <CareerCenterCTA />
        </div>
    )
}

export default Career;