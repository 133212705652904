import VideoContainer from "../../component/VideoContainer";
import { useTranslation } from 'react-i18next';

function OCSectionVideoGrid() {
    const { t } = useTranslation();
    return(
        <div className=" px-[2.625rem] mt-[9.5rem] lg:mt-[10.3rem] lg:text-center">
            <p className="font-poppins font-bold text-[6.667vw] lg:text-[2rem]">{t('youtube.header')}</p>
            <p className="font-inter text-[4.167vw] mt-[1rem] lg:max-w-[52rem] lg:mx-auto lg:text-[1.25rem]">{t('youtube.subtitle')}</p>
            <div className="flex flex-col lg:flex-row gap-[1.875rem] mt-[2rem] lg:mt-[3.625rem] items-center">
            <VideoContainer embedId="Lumjl7qNcy0" />
            <VideoContainer embedId="UiASa4VYg04" />
            <VideoContainer embedId="wV9j-bHhHgA" />
            </div>
            <div className="text-center mb-[8.375rem]">
                <a href="https://www.youtube.com/@highreachingclub" target="_blank">
            <button className="w-full mt-[1.875rem] lg:mt-[5rem] lg:max-w-[24.688rem] rounded-[0.188rem] h-[5rem] bg-hrcRed font-inter font-bold text-[4.167vw] lg:text-[1.25rem]">{t('youtube.button')}</button>
            </a>
            </div>
        </div>
    )
}

export default OCSectionVideoGrid;