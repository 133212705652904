import logo from './logo.svg';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import Home from './pages/Home.js';
import About from './pages/About.js';
import Career from './pages/Career.js';
import Contact from './pages/Contact.js';
import OnlineCourse from './pages/OnlineCourse.js';
import Programs from './pages/Programs.js';
import Navbar from './component/Navbar.js';
import Footer from "./component/Footer.js";
import ScrollToTop from './ScrollToTop';
// import SubmissionForm from './pages/SubmissionForm.js';

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    // <h1 className="text-3xl text-lime-200 font-bold underline">
    //   Hello world!
    // </h1>
    <>
    <Navbar/>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/career" element={<Career/>} />
      <Route path="/programs" element={<Programs/>} />
      <Route path="/online-course" element={<OnlineCourse/>} />
      <Route path="/about-us" element={<About/>} />
      <Route path="/contact-us" element={<Contact/>} />
      {/* <Route path="/free-trial-form" element={<SubmissionForm />} /> */}
    </Routes>
    <Footer />
    </>
  );
}

export default App;
