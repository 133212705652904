import { Trans, useTranslation } from "react-i18next";

const CareerSectionGrids = () => {
  const { t } = useTranslation();
  const bgStyle = {
    backgroundImage:
      'url("https://images.unsplash.com/photo-1677746792119-d8f8ee8e77f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1960&q=80")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    // big parent container
    <div>
      {/* desktop flex */}
      <div className="hidden lg:mt-[10rem] lg:inline-block mx-[8.313rem]">
        <div className="flex flex-row gap-[4.6rem] justify-center items-center">
          <img
            src="https://d3hde7ibm2i7sg.cloudfront.net/career-page-assets/career-desktop-assets/CPlayerDevelopment.png"
            className="aspect-[613/400] lg:max-w-[38.3rem] inline-block"
            alt="hrcfa-career-center"
          />
          <div>
            <p className="font-poppins font-bold text-[2rem]">
              {t("career_center.grid.grid_1.header")}
            </p>
            <p className="mt-[1.75rem] font-inter text-[1.25rem]">
              {t("career_center.grid.grid_1.subtitle")}
            </p>
          </div>
        </div>
        <div className="flex flex-row mt-[8.5rem] gap-[4.6rem] justify-center items-center">
          <div>
            <p className="font-poppins font-bold text-[2rem]">
              {t("career_center.grid.grid_2.header")}
            </p>
            <p className="mt-[1.75rem] font-inter text-[1.25rem]">
              {t("career_center.grid.grid_2.subtitle")}
            </p>
          </div>
          <img
            src="https://d3hde7ibm2i7sg.cloudfront.net/career-page-assets/career-desktop-assets/CProfessionalCareer.png"
            className="aspect-[613/400] lg:max-w-[38.3rem] inline-block"
            alt="hrcfa-career-center"
          />
        </div>
      </div>
      {/* mobile flex */}
      <div className="flex flex-col px-[2.625rem] lg:hidden">
        <div className="relative mx-auto h-[28.875rem] w-full rounded-xl career-card-1 px-4 pb-14 mt-[4.125rem]">
          <div className="absolute bottom-[2rem] space-y-4 text-white">
            <h3 className="font-semibold font-inter text-[1.5rem]">
              {t("career_center.grid.grid_1.header")}
            </h3>
            <p className="text-[1rem] font-inter">
              {t("career_center.grid.grid_1.subtitle")}
            </p>
          </div>
          {/* <div className="flex flex-col justify-between gap-y-8 pt-8 pb-12">
      <ul className="flex flex-col gap-y-2.5">
        <li className="flex space-x-3">
          <img width="50" height="50" src="https://img.icons8.com/ios/50/ffffff/checked--v1.png" alt="Checked Icon" className="w-6 h-6" />
          <span className="font-bold">List Item</span>
        </li>
      </ul>
      <div>
        <a href="#" className="rounded-full bg-white px-6 py-3 text-center font-medium">Learn More</a>
      </div>
    </div> */}
        </div>
        <div className="relative mx-auto h-[28.875rem] w-full rounded-xl career-card-2 px-4 pb-14 mt-[4.125rem]">
          <div className="absolute bottom-[2rem] space-y-4 text-white">
            <h3 className="font-semibold font-inter text-[1.5rem]">
              {t("career_center.grid.grid_2.header")}
            </h3>
            <p className="text-[1rem] font-inter">
              {t("career_center.grid.grid_2.subtitle")}
            </p>
          </div>
          {/* <div className="flex flex-col justify-between gap-y-8 pt-8 pb-12">
      <ul className="flex flex-col gap-y-2.5">
        <li className="flex space-x-3">
          <img width="50" height="50" src="https://img.icons8.com/ios/50/ffffff/checked--v1.png" alt="Checked Icon" className="w-6 h-6" />
          <span className="font-bold">List Item</span>
        </li>
      </ul>
      <div>
        <a href="#" className="rounded-full bg-white px-6 py-3 text-center font-medium">Learn More</a>
      </div>
    </div> */}
        </div>
      </div>
    </div>
  );
};

export default CareerSectionGrids;
