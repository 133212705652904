import FeatureCarousel from '../component/FeatureCarousel.js';
import { Trans, useTranslation } from 'react-i18next';
import {Link , useLocation} from 'react-router-dom';

function FeatureSectionHome() {
    const { t } = useTranslation();
    const location = useLocation();
    return (
        // <div className="grid grid-cols-3">
        //     <div className="col-span-1 flex items-center justify-center">
        //         <p>Si dolor amet</p>
        //     </div>
        //     <div className="col-span-2">
        //         <FeatureCarousel />
        //     </div>
        // </div>
        <div className="flex flex-col lg:flex-row">
  
  <div className="md:flex md:flex-col md:w-1/3 w-full text-center lg:text-left lg:pl-[4rem] lg:mr-12">
      <p className="font-poppins font-bold text-[6.667vw] lg:text-[2rem] lg:text-[2rem] lg:mt-[2rem]">{t('home.feature.header')}</p>
      <p className="font-poppins text-[4.167vw] lg:text-[1.25rem] lg:text-[1.25rem] px-[2.625rem] lg:max-w-[25rem] lg:px-0 mt-[1.125rem]">{t('home.feature.subtitle')}</p>
      <Link to="/programs"><button className="hidden w-full md:inline-block mt-[2.25rem] lg:max-w-[20.75rem] rounded-[0.188rem] font-medium text-[1.25rem] h-[3.75rem] bg-hrcRed font-inter">{t('home.feature.button')}</button></Link>
  </div>

  
  <div className=" hide-button-on-small w-full mt-[2.5rem] md:mt-0 md:w-2/3">
    <FeatureCarousel />
  </div>

  <div className="w-full md:hidden text-center px-[2.625rem]">
      <Link to="/programs"><button className="mt-[3.625rem] mb-[7.438rem] bg-hrcRed font-inter rounded-[0.188rem] font-medium text-[4.167vw] lg:text-[1.25rem] w-full h-[3.75rem] md:hidden">{t('home.feature.button')}</button></Link>
    </div>
</div>
    )
}

export default FeatureSectionHome;