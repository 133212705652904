import { FaEnvelope } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

function ContactSectionSocials() {
    const { t } = useTranslation();
    return(
        <div className="mt-[9.5rem]">
            <div className="text-center">
            <p className="text-[6.667vw] lg:text-[2rem] font-poppins font-bold">{t('contact.header')}</p>
            </div>
            <div className="mt-[2.688rem] flex flex-col px-[2.625rem]">
                <div className="flex flex-row gap-[1.875rem] justify-start items-center">
                <a href="https://wa.me/6288210100799" target="_blank"><FaWhatsapp className="text-[1.813rem]" /></a>
                <p className="text-[4.167vw] lg:text-[1.25rem] font-inter">+62 8821 0100 799</p>
                </div>
                <div className="flex flex-row mt-[2.813rem] gap-[1.875rem] justify-start items-center">
                <a href="https://www.instagram.com/hrc.fa/" target="_blank"><FaInstagram className="text-[1.813rem]" /></a>
                <p className="text-[4.167vw] lg:text-[1.25rem] font-inter">hrc.fa</p>
                </div>
                <div className="flex flex-row mt-[2.813rem] gap-[1.875rem] justify-start items-center">
                <a href="https://www.tiktok.com/@hrc.fa" target="_blank"><FaTiktok className="text-[1.813rem]" /></a>
                <p className="text-[4.167vw] lg:text-[1.25rem] font-inter">hrc.fa</p>
                </div>
                <div className="flex flex-row mt-[2.813rem] gap-[1.875rem] justify-start items-center">
                <a href="https://www.youtube.com/@highreachingclub" target="_blank"><FaYoutube className="text-[1.813rem]" /></a>
                <p className="text-[4.167vw] lg:text-[1.25rem] font-inter">HRC Football Academy</p>
                </div>
            </div>
            <div className="px-[2.625rem] w-full mt-[4rem] mb-[8.438rem]">
                <a href="https://wa.me/6288210100799" target="_blank">
            <button className="contact-us-socials-button bg-hrcRed lg:max-w-[24.688rem] font-medium text-[4.167vw] lg:text-[1.25rem] w-full h-[3.75rem]">{t('navbar.contact')}</button>
            </a>
            </div>
        </div>
    )
}

export default ContactSectionSocials;