import { useTranslation } from "react-i18next";

function AboutSectionVisionMission() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="lg:hidden mt-[5.563rem] vision-mission-linear-gradient px-[2.625rem] text-center h-[100vh]">
        <div className="pt-[8.188rem]">
          <p className="font-poppins font-bold text-[6.667vw] lg:text-[2rem]">
            {t("about.vision_header")}
          </p>
          <p className="mt-[0.875rem] font-inter text-[4.167vw] lg:text-[1.25rem]">
            {t("about.vision_subtitle")}
          </p>
        </div>
        <div className="mt-[7.5rem]">
          <p className="font-poppins font-bold text-[6.667vw] lg:text-[2rem]">
            {t("about.mission_header")}
          </p>
          <p className="mt-[0.875rem] font-inter text-[4.167vw] lg:text-[1.25rem]">
            {t("about.mission_subtitle")}
          </p>
        </div>
      </div>
      <div className="hidden lg:inline-block lg:flex lg:flex-row lg:justify-center lg:items-center lg:gap-[5rem]">
        <img
          className="aspect-[668/453] lg:max-w-[41.75rem]"
          src="https://d3hde7ibm2i7sg.cloudfront.net/about-page-assets/about-desktop-assets/CVisionMission.png"
        />
        <div className="flex flex-col lg:max-w-[34rem]">
          <div className="">
            <p className="font-poppins font-bold text-[6.667vw] lg:text-[2rem]">
              {t("about.vision_header")}
            </p>
            <p className="mt-[0.875rem] font-inter text-[4.167vw] lg:text-[1.25rem]">
              {t("about.vision_subtitle")}
            </p>
          </div>
          <div className="mt-[7rem]">
            <p className="font-poppins font-bold text-[6.667vw] lg:text-[2rem]">
              {t("about.mission_header")}
            </p>
            <p className="mt-[0.875rem] font-inter text-[4.167vw] lg:text-[1.25rem]">
              {t("about.mission_subtitle")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSectionVisionMission;
