import { useTranslation } from "react-i18next";

function AboutSectionAcademyPhilosophy() {
  const { t } = useTranslation();
  return (
    <div className="pt-32 lg:pt-0 px-[2.625rem] lg:mt-[10.4rem] text-center">
      <div className="mb-8 lg:mb-0">
        <p className="font-poppins font-bold text-[6.667vw] lg:text-[2rem]">
          {t("about.header")}
        </p>
        <p className="font-inter text-[4.167vw] lg:text-[1.25rem] lg:mt-[1rem] lg:max-w-[60rem] lg:mx-auto">
          {t("about.subtitle")}
        </p>
      </div>
      <div className="lg:hidden mt-[7.688rem]">
        <p className="text-[6.667vw] lg:text-[2rem] font-poppins font-bold">
          {t("about.philosophy_header")}
        </p>
        <img
          className="mt-[1.438rem] inline-block"
          src="https://d3hde7ibm2i7sg.cloudfront.net/about-page-assets/CASpanishPhilosophyM.png"
          alt="hrcfa-spanish-philosophy"
        />
        <p className="mt-[1.125rem] font-inter text-[4.167vw] lg:text-[1.25rem]">
          {t("about.philosophy_subtitle")}
        </p>
      </div>
      <div className="hidden lg:inline-block mt-[7.688rem] lg:mb-[6rem] lg:flex lg:flex-row lg:justify-center lg:items-center lg:gap-[5rem]">
        <div className="lg:max-w-[33rem] lg:text-left">
          <p className="text-[6.667vw] lg:text-[2rem] font-poppins font-bold">
            {t("about.philosophy_header")}
          </p>
          <p className="mt-[1.125rem] font-inter text-[4.167vw] lg:text-[1.25rem]">
            {t("about.philosophy_subtitle")}
          </p>
        </div>
        <img
          className="inline-block lg:aspect-[670/453] lg:max-w-[41.75rem]"
          src="https://d3hde7ibm2i7sg.cloudfront.net/about-page-assets/about-desktop-assets/CSpanishPhilosophy.png"
          alt="hrcfa-spanish-philosophy"
        />
      </div>
    </div>
  );
}

export default AboutSectionAcademyPhilosophy;
