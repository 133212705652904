import { Trans, useTranslation } from 'react-i18next';
function ProgramSectionHeader() {
    const { t } = useTranslation();
    return (
        <div className="mt-[9.5rem] lg:mt-[10.375rem] px-[2.625rem] lg:max-w-[38rem] lg:mx-auto lg:text-center">
            <p className="text-[6.667vw] lg:text-[2rem] font-bold font-poppins">{t('programs.topsection.header')}</p>
            <p className="mt-[0.938rem] font-inter text-[4.167vw] lg:text-[1.25rem]">{t('programs.topsection.subtitle')}</p>
        </div>
    )
}

export default ProgramSectionHeader;