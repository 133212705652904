import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import { useTranslation } from 'react-i18next';
import { TbTable } from "react-icons/tb";

const ContactSectionFAQ = () => {
    const { t } = useTranslation();

  return (
    <div className="text-center mt-[8.063rem] lg:mt-[8.938rem] mb-[43rem]">
        <p className="text-[6.667vw] lg:text-[2rem] font-bold font-poppins">{t('contact.faq_header')}</p>
    <Accordion className="w-[80%] mr-auto ml-auto mt-[2.438rem]" transition={{duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)"}}>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-b p-4">
                    <span className="font-inter text-[4.167vw] lg:text-[1.25rem] font-bold">{t('contact.academy_category')}</span>
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                </AccordionHeader>

                <AccordionBody>
                <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] p-4">
                    <span className="font-inter text-left text-[4.167vw] lg:text-[1.25rem]">{t('contact.academy_qn1')}</span>
                    <div className="w-10 h-fit">
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    </div>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.academy_ans1')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t p-4">
                    <span className="font-inter text-left text-[4.167vw] lg:text-[1.25rem]">{t('contact.academy_qn2')}</span>
                    <div className="w-10 h-fit">
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    </div>
                    {/* <svg className={`w-10 h-10 ${!open ? '' : 'rotate-180'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M5.293 15.707a1 1 0 001.414 0L10 12.414l3.293 3.293a1 1 0 001.414-1.414l-4-4a1 1 0 00-1.414 0l-4 4a1 1 0 000 1.414z" clipRule="evenodd" />
</svg> */}
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.academy_ans2')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t p-4">
                    <span className="font-inter text-left text-[4.167vw] lg:text-[1.25rem]">{t('contact.academy_qn3')}</span>
                    <div className="w-10 h-fit">
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    </div>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.academy_ans3')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t p-4">
                    <span className="font-inter text-left text-[4.167vw] lg:text-[1.25rem]">{t('contact.academy_qn4')}</span>
                    <div className="w-10 h-fit">
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    </div>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.academy_ans4')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t p-4">
                    <span className="font-inter text-left text-[4.167vw] lg:text-[1.25rem]">{t('contact.academy_qn5')}</span>
                    <div className="w-10 h-fit">
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    </div>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.academy_ans5')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t p-4">
                    <span className="font-inter text-left text-[4.167vw] lg:text-[1.25rem]">{t('contact.academy_qn9')}</span>
                    <div className="w-10 h-fit">
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    </div>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.academy_ans9')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t p-4">
                    <span className="font-inter text-left text-[4.167vw] lg:text-[1.25rem]">{t('contact.academy_qn8')}</span>
                    <div className="w-10 h-fit">
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    </div>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.academy_ans8')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t p-4">
                    <span className="font-inter text-left text-[4.167vw] lg:text-[1.25rem]">{t('contact.academy_qn7')}</span>
                    <div className="w-10 h-fit">
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    </div>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.academy_ans7')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t p-4">
                    <span className="font-inter text-left text-[4.167vw] lg:text-[1.25rem]">{t('contact.academy_qn6')}</span>
                    <div className="w-10 h-fit">
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    </div>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.academy_ans6')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-b p-4">
                    <span className="font-inter text-[4.167vw] lg:text-[1.25rem] font-bold">{t('contact.registration_category')}</span>
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                </AccordionHeader>

                <AccordionBody>
                <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t py-4">
                    <span className="font-inter text-[4.167vw] lg:text-[1.25rem]">{t('contact.registration_qn1')}</span>
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.registration_ans1')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t py-4">
                    <span className="font-inter text-[4.167vw] lg:text-[1.25rem]">{t('contact.registration_qn2')}</span>
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.registration_ans2')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t py-4">
                    <span className="font-inter text-[4.167vw] lg:text-[1.25rem]">{t('contact.registration_qn3')}</span>
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.registration_ans3')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t py-4">
                    <span className="font-inter text-[4.167vw] lg:text-[1.25rem]">{t('contact.registration_qn4')}</span>
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.registration_ans4')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t py-4">
                    <span className="font-inter text-[4.167vw] lg:text-[1.25rem]">{t('contact.registration_qn5')}</span>
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.registration_ans5')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t py-4">
                    <span className="font-inter text-[4.167vw] lg:text-[1.25rem]">{t('contact.registration_qn6')}</span>
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.registration_ans6')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
    <AccordionItem>
        {({open}) => (
            <>
                <AccordionHeader className="w-full flex justify-between items-center text-[#fff] border-t py-4">
                    <span className="font-inter text-[4.167vw] lg:text-[1.25rem]">{t('contact.registration_qn7')}</span>
                    <svg className={`grow-0 shrink-0 w-10 max-w-10 ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                </AccordionHeader>

                <AccordionBody>
                    <div className="p-4 font-bold text-[4.167vw] lg:text-[1.25rem] font-inter">
                        {t('contact.registration_ans7')}
                    </div>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
                </AccordionBody>
            </>
        )}
    </AccordionItem>
</Accordion>
</div>
  );
};

export default ContactSectionFAQ;