import ProgramPageCarousel from "../component/ProgramPageCarousel";
import PricingSectionHome from "../sections/PricingSectionHome";
import ProgramSectionHeader from "../sections/programs-features-page-sections/ProgramSectionHeader";
import ProgramSectionHomebase from "../sections/programs-features-page-sections/ProgramSectionHomebase";
import ProgramSectionJourneyM from "../sections/programs-features-page-sections/ProgramSectionJourneyM";
import { useTranslation } from 'react-i18next';

function Programs() {
    const { t } = useTranslation();
    return(
        <div>
            <ProgramSectionHeader />
            <ProgramSectionJourneyM />
            <div className="lg:text-center">
                <p className="px-[2.625rem] mb-[1rem] font-poppins text-[6.667vw] lg:text-[2rem] font-bold">{t('programs.program.header')}</p>
                <p className="px-[2.625rem] lg:px-0 inline-block mx-auto mb-[2.5rem] lg:max-w-[40rem] lg:mb-[3.7rem] font-inter text-[4.167vw] lg:text-[1.25rem]">{t('programs.program.subtitle')}</p>
            <ProgramPageCarousel />
            </div>
            <ProgramSectionHomebase />
            <div className="mt-[10.5rem]"></div>
        </div>
    )
}

export default Programs;