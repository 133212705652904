import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import steve from '../svg-assets/testimonial-assets/Steve.webp';
import siti from '../svg-assets/testimonial-assets/Siti.webp';
import masruri from '../svg-assets/testimonial-assets/Masuri.webp';
import thp from '../svg-assets/testimonial-assets/TioHiapPhuang.webp';
import hnf from '../svg-assets/testimonial-assets/HenryFebe.webp';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import { Trans, useTranslation } from 'react-i18next';

function TestimonialHome() {
    const { t } = useTranslation();
    const reviews =[
        {
            "review": t('home.testimonial.customer_1.review'),
            "image": steve,
            "name": t('home.testimonial.customer_1.name'),
            "category": t('home.testimonial.customer_1.category'),
        },
        {
            "review": t('home.testimonial.customer_2.review'),
            "image": siti,
            "name": t('home.testimonial.customer_2.name'),
            "category": t('home.testimonial.customer_2.category'),
        },
        {
            "review": t('home.testimonial.customer_3.review'),
            "image": masruri,
            "name": t('home.testimonial.customer_3.name'),
            "category": t('home.testimonial.customer_3.category'),
        },
        {
            "review": t('home.testimonial.customer_4.review'),
            "image": thp,
            "name": t('home.testimonial.customer_4.name'),
            "category": t('home.testimonial.customer_4.category'),
        },
        {
            "review": t('home.testimonial.customer_5.review'),
            "image": hnf,
            "name": t('home.testimonial.customer_5.name'),
            "category": t('home.testimonial.customer_5.category'),
        },
    ]
    return(
        <div className="mt-[6.5rem] lg:mt-[13.5rem] max-w-6xl mx-auto space-y-4 text-center">
        <p className="text-[6.667vw] lg:text-[2rem] font-poppins font-bold">{t('home.testimonial.header')}</p>
        {/* <p className="text-base text-gray-500">{t('home.testimonial.subtitle')}</p> */}
        <div className="relative">
            <Swiper
            navigation={true}
            pagination= {{
                el: '.swiper-custom-pagination',
                clickable: true
            }}
            modules={[Navigation, Pagination]}
            className="h-[28rem] relative overflow-visible testimonial-custom-swiper"
            >
                {reviews.map((review, index)=>{
                    return(
                        <SwiperSlide key={index}>
                            <div className="bg-[#000000] h-[100%] flex flex-col items-center px-12 space-y-4">
                                <img className="h-[3.563rem] w-[3.563rem] rounded-full" src={review.image} alt="review-image"/>
                                <p className="text-[4.167vw] lg:text-[1.25rem] font-inter">{review.review}</p>
                                <p className="font-semibold text-[4.167vw] lg:text-[1.25rem] font-inter">-{review.name}-</p>
                                <p className="font-semibold text-[4.167vw] lg:text-[1.25rem] font-inter">{review.category}</p>
                            </div>
                        </SwiperSlide>
                    )
                })
            }
            </Swiper>
            <div className="swiper-custom-pagination"></div>
        </div>
    </div>
    )
}

export default TestimonialHome;