import VideoContainer from '../component/VideoContainer';
import { Trans, useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';

function CourseSectionHome() {
    const { t } = useTranslation();
    return (
        <div>
        <div className="flex flex-col items-center text-center mt-[7.438rem] lg:mt-[13.5rem] mx-[2.625rem]">
            <p className="font-poppins font-bold mb-[2rem] lg:mb-0 text-center text-[6.667vw] lg:text-[2rem]">{t('home.course.header')}</p>
            <p className="hidden lg:inline-block lg:mt-[1rem] lg:mb-[3.375rem] lg:max-w-[56.25rem] font-poppins text-[4.167vw] lg:text-[1.25rem]">{t('home.course.subtitle')}</p>
            <VideoContainer embedId="UiASa4VYg04" />
            <p className="lg:hidden mt-[1.443rem] lg:max-w-[56.25rem] font-poppins text-[4.167vw] lg:text-[1.25rem]">{t('home.course.subtitle')}</p>
        </div>
        <div className="mx-[2.625rem] text-center lg:mx-auto">
        <Link to="/online-course">
        <button className="mt-[2.25rem] lg:mt-[3.25rem] mb-[8.188rem] lg:mb-[14.25rem] bg-hrcRed lg:max-w-[24.688rem] font-inter rounded-[0.188rem] font-medium text-[4.167vw] lg:text-[1.25rem] w-full h-[3.75rem]">{t('home.course.button')}</button>
        </Link>
        </div>
        </div>
    )
}

export default CourseSectionHome;