import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

import { FreeMode, Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";

// import { RxArrowTopRight } from "react-icons/rx";

const ProgramPageCarousel = () => {
  const { t } = useTranslation();
  const ServiceData = [
    {
      title: t("feature_card.training_3"),
      content: "Lorem ipsum dolor sit /amet, consectetur adipiscing elit.",
      backgroundImage:
        "https://d3hde7ibm2i7sg.cloudfront.net/C3xTrainingProgram.png",
    },
    {
      title: t("feature_card.friendly_match"),
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      backgroundImage:
        "https://d3hde7ibm2i7sg.cloudfront.net/CFriendlyMatch.png",
    },
    {
      title: t("feature_card.nutrition"),
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      backgroundImage:
        "https://d3hde7ibm2i7sg.cloudfront.net/CNutritionProgram.png",
    },
    {
      title: t("feature_card.fitness"),
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      backgroundImage:
        "https://d3hde7ibm2i7sg.cloudfront.net/CFitnessProgram.png",
    },
    {
      title: t("feature_card.report"),
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      backgroundImage:
        "https://d3hde7ibm2i7sg.cloudfront.net/CIndividualReport.png",
    },
    {
      title: t("feature_card.itraining"),
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      backgroundImage:
        "https://d3hde7ibm2i7sg.cloudfront.net/CIndividualTraining.png",
    },
    {
      title: t("feature_card.networking"),
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      backgroundImage:
        "https://d3hde7ibm2i7sg.cloudfront.net/CProClubNetworking.png",
    },
    {
      title: t("feature_card.tournament"),
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      backgroundImage: "https://d3hde7ibm2i7sg.cloudfront.net/CTournament.png",
    },
    {
      title: t("feature_card.community"),
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      backgroundImage:
        "https://d3hde7ibm2i7sg.cloudfront.net/CCommunityEvent.png",
    },
  ];
  return (
    <div className="lg:px-[10rem] pl-[2.625rem] md:ml-0 flex items-center justify-center flex-col">
      <Swiper
        breakpoints={{
          340: {
            slidesPerView: 1.4,
            spaceBetween: 25,
          },
          700: {
            slidesPerView: 2.3,
            spaceBetween: 20,
          },
          1000: {
            slidesPerView: 3,
            spaceBetween: 20,
            slidesPerGroup: 3,
          },
        }}
        freeMode={true}
        navigation={true}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[FreeMode, Navigation]}
        className="max-w-[100%] lg:max-w-[100%]"
      >
        {ServiceData.map((item) => (
          <SwiperSlide key={item.title}>
            {/* <div className="flex flex-col gap-6 mb-20 group relative shadow-lg text-white rounded-xl px-6 py-8 h-[29.375rem] w-[19.5rem] lg:h-[437px] lg:w-[363px] overflow-hidden cursor-pointer"> */}
            <div className="flex flex-col gap-6 group relative shadow-lg text-white px-6 py-8 aspect-[312/470] overflow-hidden cursor-pointer">
              <div
                className="absolute inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url(${item.backgroundImage})` }}
              />
              <div className="absolute inset-0 bg-black opacity-10" />
              <div className="absolute bottom-[2.5rem] flex text-left justify-start flex-col gap-3">
                {/* <item.icon className="text-blue-600 group-hover:text-blue-400 w-[32px] h-[32px]" /> */}
                <h1 className="text-[6.667vw] lg:max-w-[17rem] lg:text-[2rem] font-bold font-inter">
                  {item.title}{" "}
                </h1>
                {/* <p className="lg:text-[18px]">{item.content} </p> */}
              </div>
              {/* <RxArrowTopRight className="absolute bottom-5 left-5 w-[35px] h-[35px] text-white group-hover:text-blue-500 group-hover:rotate-45 duration-100" /> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProgramPageCarousel;
