import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function SelectLanguage() {
  const { t, i18n } = useTranslation();

  const languageMap = {
    en: { label: t('language.english') },
    id: { label: t('language.indonesian') },
  };

  const handleLanguageChange = (event) => {
    i18next.changeLanguage(event.target.value);
  };

  return (
    <div>
      {/* <div className="text-gray-500 px-4 py-2 text-sm">
        {t('language.select_language')}
      </div> */}
      <div class="grid w-[5rem] lg:absolute lg:top-[5rem] lg:right-[2rem] grid-cols-2 gap-2 rounded-md bg-gray-200 bg-opacity-50">
      {Object.keys(languageMap).map((langKey) => (
        <div key={langKey}>
          <input
            type="radio"
            name="language"
            id={langKey}
            value={langKey}
            className="peer hidden"
            checked={i18n.language.substring(0, 2) === langKey}
            onChange={handleLanguageChange}
          />
          <label
            htmlFor={langKey}
            className="block cursor-pointer opacity-50 select-none rounded-md p-2 text-center peer-checked:bg-[#fff] peer-checked:font-bold peer-checked:opacity-100 peer-checked:text-[#000000] text-[0.813rem] text-[#fff]"
          >
            {languageMap[langKey].label}
          </label>
        </div>
      ))}
      </div>
    </div>
  );
}

export default SelectLanguage;