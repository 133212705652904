import { useState, useEffect } from "react";
import ContactSectionFAQ from "../sections/contact-us-page-sections/ContactSectionFAQ";
import ContactSectionSocials from "../sections/contact-us-page-sections/ContactSectionSocials";
import HeroContact from "../component/HeroContact";

function Contact() {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1000);

    const updateMedia = () => {
      setDesktop(window.innerWidth > 1000);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
    return(
        <div>
            {isDesktop ? <HeroContact /> : <ContactSectionSocials />}
            <ContactSectionFAQ />
        </div>
    )
}

export default Contact;