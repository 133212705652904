import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

function CareerSectionHome() {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div className="flex text-center flex-col md:justify-center md:items-center md:flex-row lg:gap-[4.8rem] lg:text-left lg:mt-[14rem]">
      <img
        className="hidden lg:inline-block lg:ml-[6rem] max-w-[43rem] inline-block mr-auto ml-auto lg:basis-1/2"
        src="https://i.imgur.com/uGfDWsg.png"
        alt="HRC Career Center"
      />
      <img
        className="lg:hidden w-full px-[2.625rem] lg:ml-[6rem] inline-block lg:basis-1/2"
        src="https://i.imgur.com/N83y2MO.png"
        alt="HRC Career Center"
      />
      <div className="lg:basis-1/2">
        <p className="mt-[2.063rem] font-poppins font-bold lg:text-[2rem] text-[6.667vw] lg:text-[2rem]">
          {t("home.career.header")}
        </p>
        <p className="mx-[2.625rem] lg:mx-0 md:mx-auto mt-[0.438rem] font-poppins lg:text-[1.25rem] lg:max-w-[33rem] text-[4.167vw] lg:text-[1.25rem]">
          {t("home.career.subtitle")}
        </p>
        <div className="mx-[2.625rem] lg:mx-0">
          <Link to="/career">
            <button className="mt-[2.568rem] bg-hrcRed font-inter rounded-[0.188rem] font-medium lg:text-[1.25rem] text-[4.167vw] lg:text-[1.25rem] lg:max-w-[20.75rem] w-full h-[3.75rem]">
              {t("home.career.button")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CareerSectionHome;
