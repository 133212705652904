import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function AboutSectionCoaches() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  const coachArray = [
    {
      name: "https://d3hde7ibm2i7sg.cloudfront.net/about-page-assets/coaching-staff-assets/CCoach1.png",
      fade_delay: 700,
    },
    {
      name: "https://d3hde7ibm2i7sg.cloudfront.net/about-page-assets/coaching-staff-assets/CCoach2.png",
      fade_delay: 1200,
    },
    {
      name: "https://d3hde7ibm2i7sg.cloudfront.net/about-page-assets/coaching-staff-assets/CCoach3.png",
      fade_delay: 1700,
    },
    {
      name: "https://d3hde7ibm2i7sg.cloudfront.net/about-page-assets/coaching-staff-assets/CCoach4.png",
      fade_delay: 2200,
    },
  ];
  return (
    <div className="px-[2.625rem] lg:px-[5.125rem] mt-[7.813rem] text-center">
      <p className="font-bold font-poppins text-[6.667vw] lg:text-[2rem]">
        {t("about.coaches_header")}
      </p>
      <p className="hidden lg:inline-block lg:max-w-[65rem] mt-[1rem] font-inter text-[4.167vw] lg:text-[1.25rem]">
        {t("about.coaches_description")}
      </p>
      <div className="mt-[2.5rem] lg:mt-[3.5rem] grid grid-cols-2 lg:grid-cols-4 gap-[1.25rem]">
        {coachArray.map((element) => {
          return (
            <img
              data-aos-delay={element.fade_delay}
              data-aos-easing="ease-in"
              data-aos="fade-down"
              src={element.name}
              alt="hrcfa-coach"
            />
          );
        })}
      </div>
      <p className="lg:hidden mt-[2.375rem] font-inter text-[4.167vw] lg:text-[1.25rem]">
        {t("about.coaches_description")}
      </p>
    </div>
  );
}

export default AboutSectionCoaches;
