// import { Swiper, SwiperSlide } from "swiper/react";

// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/free-mode";

// import { FreeMode, Pagination, Navigation } from "swiper/modules";

// import { RxArrowTopRight } from "react-icons/rx";
// import { ServiceData } from "../constants";

// function SuccessPlayerHome() {
//     return (
//         <div className="flex items-center justify-center flex-col h-[600px] bg-white">
//       <Swiper
//         breakpoints={{
//           340: {
//             slidesPerView: 2,
//             spaceBetween: 15,
//           },
//           700: {
//             slidesPerView: 3,
//             spaceBetween: 15,
//           },
//         }}
//         freeMode={true}
//         pagination={{
//           clickable: true,
//         }}
//         navigation={true}
//         modules={[FreeMode, Pagination, Navigation]}
//         className="max-w-[50%] lg:max-w-[80%]"
//       >
//         {ServiceData.map((item) => (
//           <SwiperSlide key={item.title}>
//             <div className="flex flex-col gap-6 mb-20 group relative shadow-lg text-white rounded-xl px-6 py-8 h-[250px] w-[215px] lg:h-[350px] lg:w-[300px] overflow-hidden cursor-pointer">
//               <div
//                 className="absolute inset-0 bg-cover bg-center"
//                 style={{ backgroundImage: `url(${item.backgroundImage})` }}
//               />
//               <div className="absolute inset-0 bg-black opacity-10 group-hover:opacity-50" />
//               <div className="relative flex flex-col gap-3">
//                 <item.icon className="text-blue-600 group-hover:text-blue-400 w-[32px] h-[32px]" />
//                 <h1 className="text-xl lg:text-2xl">{item.title} </h1>
//                 <p className="lg:text-[18px]">{item.content} </p>
//               </div>
//               <RxArrowTopRight className="absolute bottom-5 left-5 w-[35px] h-[35px] text-white group-hover:text-blue-500 group-hover:rotate-45 duration-100" />
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </div>
//     )
// }

// export default SuccessPlayerHome;

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import placeholderImage from "../svg-assets/placeholder.jpeg";

// const data = [
//   {
//     name: `John Morgan`,
//     img: placeholderImage,
//     review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
//   },
//   {
//     name: `Ellie Anderson`,
//     img: placeholderImage,
//     review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
//   },
//   {
//     name: `Nia Adebayo`,
//     img: placeholderImage,
//     review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
//   },
//   {
//     name: `Rigo Louie`,
//     img: placeholderImage,
//     review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
//   },
//   {
//     name: `Mia Williams`,
//     img: placeholderImage,
//     review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
//   },

// ];

// function SuccessPlayerHome() {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1
//   };
//   return (
//     <div className='w-3/4 m-auto'>
//       <div className="mt-20">
//       <Slider {...settings}>
//         {data.map((d) => (
//           <div key={d.name} className="bg-white h-[450px] text-black rounded-xl">
//             <div className='h-56 bg-indigo-500 flex justify-center items-center rounded-t-xl'>
//               <img src={d.img} alt="" className="h-44 w-44 rounded-full"/>
//             </div>

//             <div className="flex flex-col items-center justify-center gap-4 p-4">
//               <p className="text-xl font-semibold">{d.name}</p>
//               <p className="text-center">{d.review}</p>
//               <button className='bg-indigo-500 text-white text-lg px-6 py-1 rounded-xl'>Read More</button>
//             </div>
//           </div>
//         ))}
//       </Slider>
//       </div>

//     </div>
//   );
// }

// export default SuccessPlayerHome;

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "../App.css";
import placeholderImage from "../svg-assets/placeholder.jpeg";
import { FreeMode, Navigation } from "swiper/modules";
import { Trans, useTranslation } from "react-i18next";

function SuccessPlayerHome() {
  const { t } = useTranslation();
  const reviews = [
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/NIZ6XiG.png",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/BsfFcaG.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/A3DAvyG.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/DCzaj14.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/itWwkkY.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/R07eswU.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/8bWhekP.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/JftS2I7.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/gCBZVNV.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/j8jsbfS.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/1oOHlqb.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/CCIJqmy.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/ksmbtoi.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/9KK1JHV.jpg",
    },
  ];
  return (
    //     <div className="max-w-6xl space-y-4 py-12 text-center">
    //     <h2 className="text-2xl font-semibold text-gray-700">Hearr What Our Customers Have to Say</h2>
    //     <p className="text-base text-gray-500">Discover Testimonials from Our Satisfied Customer</p>
    //     <div>
    //         <Swiper
    //         navigation={true}
    //         modules={[Navigation, FreeMode]}
    //         slidesPerView={1.1}
    //         className="hide-button-on-small"
    //         >
    //             {reviews.map((review, index)=>{
    //                 return(
    //                     <SwiperSlide key={index}>
    //                         <div className="player-card-home flex flex-col items-center py-4 px-2 space-y-4 aspect-[356/470]">
    //                             <img className="h-[100px] w-[100px rounded-full" src={placeholderImage} alt="review-image"/>
    //                             <p>{review.review}</p>
    //                             <h2 className="font-semibold">{review.name}</h2>
    //                             <div></div>
    //                             <p>{review.position}</p>
    //                         </div>
    //                     </SwiperSlide>
    //                 )
    //             })
    //         }
    //         </Swiper>
    //     </div>
    // </div>
    <div className="text-center mb-[8rem] lg:mb-[14rem] lg:mt-[13.75rem]">
      <p className="text-[6.667vw] lg:text-[2rem] lg:text-[2rem] mt-[7.5rem] font-poppins font-bold">
        {t("home.success.header")}
      </p>
      <p className="mt-[1.625rem] px-[2.625rem] mx-auto text-[1.25rem] font-inter">
        {t("home.success.subtitle")}
      </p>
      <div className="ml-[2.813rem] lg:mx-[10.7rem] mt-[2.125rem] md:ml-0 flex items-center justify-center flex-col">
        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            700: {
              slidesPerView: 2.3,
              spaceBetween: 20,
            },
            1000: {
              slidesPerView: 3,
              spaceBetween: 20,
              slidesPerGroup: 3,
            },
          }}
          freeMode={true}
          // pagination={{
          //   clickable: true,
          // }}
          navigation={true}
          modules={[FreeMode, Navigation]}
          className="max-w-[100%] lg:max-w-[100%] hide-button-on-small outer-arrow-success-home"
        >
          {reviews.map((item) => (
            <SwiperSlide key={item.title}>
              {/* <div className="flex flex-col gap-6 mb-20 group relative shadow-lg text-white rounded-xl px-6 py-8 h-[29.375rem] w-[19.5rem] lg:h-[437px] lg:w-[363px] overflow-hidden cursor-pointer"> */}
              <div className="flex flex-col gap-6 group relative shadow-lg text-white px-6 py-8 aspect-[356/470] overflow-hidden cursor-pointer">
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{ backgroundImage: `url(${item.bgimage})` }}
                />
                {/* <RxArrowTopRight className="absolute bottom-5 left-5 w-[35px] h-[35px] text-white group-hover:text-blue-500 group-hover:rotate-45 duration-100" /> */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default SuccessPlayerHome;
