import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import {Link , useLocation} from 'react-router-dom';
import logoHRCFA from '../svg-assets/LogoHRC.png';
import SelectLanguage from '../utility/SelectLanguage';
import { useTranslation } from 'react-i18next';


export default function Navbar() {
  const { t } = useTranslation();
  const navigation = [
    { name: t('navbar.home'), href: '/' },
    { name: t('navbar.career'), href: '/career' },
    { name: t('navbar.program'), href: '/programs' },
    { name: t('navbar.youtube'), href: '/online-course' },
    { name: t('navbar.about'), href: '/about-us' },
    { name: t('navbar.contact'), href: '/contact-us' }
  ]
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const location = useLocation();

    return (
    <>
    <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8 relative" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-auto w-[12.825rem]"
                src={logoHRCFA}
                alt="hrcfa-logo"
              />
            </Link>
          </div>
          <SelectLanguage />
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-end rounded-md p-2.5 text-[#fff]"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-9 w-9" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link key={item.name} to={item.href} className={`text-[1rem] active:font-bold leading-6 text-[#fff] ${location.pathname === item.href ? 'font-bold' : ''}`}>
                {item.name}
              </Link>
            ))}
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          {/* max-w-sm on the one below but i changed it to max-w-[100%]*/}
          <Dialog.Panel className="text-center custom-linear-gradient-navbar fixed inset-y-0 right-0 z-50 w-full overflow-y-auto pt-6 sm:max-w-[100%] sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between px-6">
              <a href="#" className="p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-auto w-[12.825rem]"
                  src={logoHRCFA}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="- rounded-md p-2.5 text-[#fff]"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-9 w-9" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-[7rem] flow-root">
              <div className=" divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={` block rounded-lg px-3 font-poppins pb-[3.5rem] uppercase text-[1.25rem] leading-7 text-[#fff] hover:opacity-100 hover:font-bold ${location.pathname === item.href ? 'font-bold' : ''} ${location.pathname === item.href ? 'opacity-100' : 'opacity-50'}`}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                {/* <div className="py-8">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-[#fff] opacity-50 hover:opacity-100"
                  >
                    Log in
                  </a>
                </div> */}
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      </>
    )
}