export const REGISTER_NOW_LINK = 'payment';

const WHATSAPP_TEXT_PRICING =
  'Halo HRCFA, Saya ingin mengetahui lebih lanjut tentang Paket Latihan yang tersedia';

export const WHATSAPP_CHAT_LINK = `https://wa.me/6288210100799?text=${encodeURI(
  WHATSAPP_TEXT_PRICING,
)}`;
const WHATSAPP_TEXT_DEFAULT =
  'Halo HRCFA, Saya ingin mengetahui lebih lanjut tentang Program Latihan yang tersedia';
export const WHATSAPP_CHAT_LINK_DEFAULT = `https://wa.me/6288210100799?text=${encodeURI(
  WHATSAPP_TEXT_DEFAULT,
)}`;
export const CLOUDFRONT_LINK = 'https://d3hde7ibm2i7sg.cloudfront.net';