import "../App.css";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

function JourneySectionHome() {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div className="text-center justify-center">
      <p className="font-poppins font-bold mt-[7.5rem] lg:mt-[14rem] text-[6.667vw] lg:text-[2rem] lg:text-[2rem]">
        {t("home.journey.header")}
      </p>
      <img
        className="hidden lg:inline-block w-6/12 mt-[1.625rem] lg:mt-[4.125rem] mx-auto"
        src="https://i.imgur.com/oRRELYs.png"
        alt="hrcfa-rookie"
      />
      <img
        className="lg:hidden px-[2.8rem] mt-[1.625rem] lg:mt-[4.125rem] mx-auto"
        src="https://i.imgur.com/v42UTAe.png"
        alt="hrcfa-rookie"
      />
      <p className="pt-[2.5rem] w-full lg:max-w-[50rem] lg:mx-auto px-[2.625rem] font-inter text-[4.167vw] lg:text-[1.25rem] lg:text-[1.25rem]">
        {t("home.journey.subtitle")}
      </p>
      {/* flex row on desktop */}
      <div className="lg:flex lg:mx-auto lg:flex-row lg:w-4/5 lg:mt-[2rem] lg:items-center lg:justify-center lg:gap-[5rem]">
        <div className="mt-[1.75rem] lg:w-full">
          <p className="text-[5vw] lg:text-[1.5rem] lg:text-[1.5rem] font-bold font-poppins ">
            {t("home.journey.rookie")}
          </p>
          <p className="text-[4.167vw] lg:text-[1.25rem] lg:text-[1.25rem] text-hrcRed font-semibold font-inter">
            {t("home.journey.rookie_age")}
          </p>
        </div>
        <div className="mt-[1.75rem] lg:w-full">
          <p className="text-[5vw] lg:text-[1.5rem] lg:text-[1.5rem] font-bold font-poppins ">
            {t("home.journey.intermediate")}
          </p>
          <p className="text-[4.167vw] lg:text-[1.25rem] lg:text-[1.25rem] text-hrcRed font-semibold font-inter">
            {t("home.journey.intermediate_age")}
          </p>
        </div>
        <div className="mt-[1.75rem] lg:w-full">
          <p className="text-[5vw] lg:text-[1.5rem] lg:text-[1.5rem] font-bold font-poppins ">
            {t("home.journey.elite")}
          </p>
          <p className="text-[4.167vw] lg:text-[1.25rem] lg:text-[1.25rem] text-hrcRed font-semibold font-inter">
            {t("home.journey.elite_age")}
          </p>
        </div>
      </div>
      <div className="px-[2.625rem]">
        <Link to="/programs">
          <button className="mt-[3.625rem] lg:max-w-[24.688rem] lg:mb-[14rem] mb-[7.438rem] bg-hrcRed font-inter rounded-[0.188rem] font-medium text-[4.167vw] lg:text-[1.25rem] lg:text-[1.25rem] w-full h-[3.75rem]">
            {t("home.journey.button")}
          </button>
        </Link>
      </div>
      {/* <img src={intermediateImage} className="parallelogram inline-block" style={{width: '200px'}} alt="hrcfa-intermediate" />
            <img src={advancedImage} className="trapezoid-inv inline-block" style={{width: '200px'}} alt="hrcfa-advanced" /> */}
    </div>
  );
}

export default JourneySectionHome;
