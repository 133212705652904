import { Trans, useTranslation } from "react-i18next";
import { TbPlayFootball } from "react-icons/tb";
import { RiTeamFill } from "react-icons/ri";
import { TbSoccerField } from "react-icons/tb";
import { TbBarbell } from "react-icons/tb";
import { MdOutlineSportsSoccer } from "react-icons/md";

function ProgramSectionJourneyM() {
  const { t } = useTranslation();
  return (
    <div>
      {/* desktop view */}
      {/* desktop view container */}
      <div>
        {/* main card flexer */}
        <div className="hidden lg:inline-block lg:mt-[4rem] lg:mx-auto lg:flex lg:flex-col lg:justify-center lg:items-center">
          {/* individual card */}
          <div className="flex flex-col rounded-lg bg-[#1C1C1C] md:w-[58rem] lg:relative md:flex-row">
            <img
              className="aspect-[561/577] lg:max-w-[35rem] basis-1/2  w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
              src="https://d3hde7ibm2i7sg.cloudfront.net/journey-assets/journey-desktop-assets/CRookie.png"
              alt=""
            />
            <div className="flex flex-col h-full basis-1/2 justify-start py-6 px-2 lg:absolute lg:max-w-[27rem] lg:top-[2rem] lg:right-[0.8rem]">
              <h5 className="text-[6.667vw] lg:text-[2rem] font-bold font-poppins">
                {t("programs.journey.rookie.name")}
              </h5>
              <p className="mb-4 font-inter text-[4.167vw] lg:text-[1.25rem]">
                {t("programs.journey.rookie.age")}
              </p>
              <ul className="flex flex-col gap-y-[1rem]">
                <li className="flex items-center justify-start space-x-3">
                  <TbPlayFootball className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.rookie.point_1")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-4">
                  <RiTeamFill className="text-[1.8rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.rookie.point_2")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbSoccerField className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.rookie.point_3")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbBarbell className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.rookie.point_4")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <MdOutlineSportsSoccer className="text-[1.9rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.rookie.point_5")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {/* second card */}
          <div className="flex flex-col mt-[5.75rem] rounded-lg bg-[#1C1C1C] md:w-[58rem] lg:relative md:flex-row">
            <img
              className="aspect-[561/577] lg:max-w-[35rem] basis-1/2  w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
              src="https://d3hde7ibm2i7sg.cloudfront.net/journey-assets/journey-desktop-assets/CIntermediate.png"
              alt=""
            />
            <div className="flex flex-col h-full basis-1/2 justify-start py-6 px-2 lg:absolute lg:max-w-[27rem] lg:top-[2rem] lg:right-[0.8rem]">
              <h5 className="text-[6.667vw] lg:text-[2rem] font-bold font-poppins">
                {t("programs.journey.intermediate.name")}
              </h5>
              <p className="mb-4 font-inter text-[4.167vw] lg:text-[1.25rem]">
                {t("programs.journey.intermediate.age")}
              </p>
              <ul className="flex flex-col gap-y-[1rem]">
                <li className="flex items-center justify-start space-x-3">
                  <TbPlayFootball className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.intermediate.point_1")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-4">
                  <RiTeamFill className="text-[1.8rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.intermediate.point_2")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbSoccerField className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.intermediate.point_3")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbBarbell className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.intermediate.point_4")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <MdOutlineSportsSoccer className="text-[1.9rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.intermediate.point_5")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {/* third card */}
          <div className="flex flex-col mt-[5.75rem] rounded-lg bg-[#1C1C1C] md:w-[58rem] lg:relative md:flex-row">
            <img
              className="aspect-[561/577] lg:max-w-[35rem] basis-1/2  w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
              src="https://d3hde7ibm2i7sg.cloudfront.net/journey-assets/journey-desktop-assets/CElite.png"
              alt=""
            />
            <div className="flex flex-col h-full basis-1/2 justify-start py-6 px-2 lg:absolute lg:max-w-[27rem] lg:top-[2rem] lg:right-[0.8rem]">
              <h5 className="text-[6.667vw] lg:text-[2rem] font-bold font-poppins">
                {t("programs.journey.elite.name")}
              </h5>
              <p className="mb-4 font-inter text-[4.167vw] lg:text-[1.25rem]">
                {t("programs.journey.elite.age")}
              </p>
              <ul className="flex flex-col gap-y-[1rem]">
                <li className="flex items-center justify-start space-x-3">
                  <TbPlayFootball className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.elite.point_1")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-4">
                  <RiTeamFill className="text-[1.8rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.elite.point_2")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbSoccerField className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.elite.point_3")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbBarbell className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.elite.point_4")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <MdOutlineSportsSoccer className="text-[1.9rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.elite.point_5")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center lg:mx-auto">
          <a href="https://wa.me/6288210100799" target="_blank">
            <button className="hidden lg:inline-block mt-[5.75rem] lg:max-w-[24.688rem] mb-[7.5rem] lg:mb-[13.3rem] bg-hrcRed font-inter rounded-[0.188rem] font-medium text-[4.167vw] lg:text-[1.25rem] w-full h-[3.75rem]">
              {t("programs.journey.button")}
            </button>
          </a>
        </div>
      </div>
      {/* mobile view */}
      <div className="lg:hidden mt-[2.125rem] flex flex-col px-[2.625rem]">
        <div className="mb-4 aspect-[395/604] relative mx-auto h-[37.75rem] w-full rounded-xl age-group-card-rookie px-6 pb-14 pt-10">
          <div className="absolute bottom-[2.688rem]">
            <div className="space-y-4 text-white">
              <h3 className="font-bold text-[6.667vw] lg:text-[2rem]">
                {t("programs.journey.rookie.name")}
              </h3>
              <p>{t("programs.journey.rookie.age")}</p>
            </div>
            <div className="flex flex-col justify-between gap-y-8 pt-8 pb-12">
              <ul className="flex flex-col gap-y-[1rem]">
                <li className="flex items-center justify-start space-x-3">
                  <TbPlayFootball className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.rookie.point_1")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-4">
                  <RiTeamFill className="text-[1.8rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.rookie.point_2")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbSoccerField className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.rookie.point_3")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbBarbell className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.rookie.point_4")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <MdOutlineSportsSoccer className="text-[1.9rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.rookie.point_5")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-4 aspect-[395/604] relative mx-auto h-[37.75rem] w-full rounded-xl age-group-card-inter px-6 pb-14 pt-10">
          <div className="absolute bottom-[2.688rem]">
            <div className="space-y-4 text-white">
              <h3 className="font-bold text-[6.667vw] lg:text-[2rem]">
                {t("programs.journey.intermediate.name")}
              </h3>
              <p>{t("programs.journey.intermediate.age")}</p>
            </div>
            <div className="flex flex-col justify-between gap-y-8 pt-8 pb-12">
              <ul className="flex flex-col gap-y-[1rem]">
                <li className="flex items-center justify-start space-x-3">
                  <TbPlayFootball className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.intermediate.point_1")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-4">
                  <RiTeamFill className="text-[1.8rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.intermediate.point_2")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbSoccerField className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.intermediate.point_3")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbBarbell className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.intermediate.point_4")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <MdOutlineSportsSoccer className="text-[1.9rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.intermediate.point_5")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-4 aspect-[395/604] relative mx-auto h-[37.75rem] w-full rounded-xl age-group-card-elite px-6 pb-14 pt-10">
          <div className="absolute bottom-[2.688rem]">
            <div className="space-y-4 text-white">
              <h3 className="font-bold text-[6.667vw] lg:text-[2rem]">
                {t("programs.journey.elite.name")}
              </h3>
              <p>{t("programs.journey.elite.age")}</p>
            </div>
            <div className="flex flex-col justify-between gap-y-8 pt-8 pb-12">
              <ul className="flex flex-col gap-y-[1rem]">
                <li className="flex items-center justify-start space-x-3">
                  <TbPlayFootball className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.elite.point_1")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-4">
                  <RiTeamFill className="text-[1.8rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.elite.point_2")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbSoccerField className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.elite.point_3")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <TbBarbell className="text-[2rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.elite.point_4")}
                  </span>
                </li>
                <li className="flex items-center justify-start space-x-3">
                  <MdOutlineSportsSoccer className="text-[1.9rem] shrink-0" />
                  <span className="font-inter">
                    {t("programs.journey.elite.point_5")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <a href="https://wa.me/6288210100799" target="_blank">
          <button className="train-with-us-button mt-[2.568rem] mb-[7.5rem] bg-hrcRed font-inter rounded-[0.188rem] font-medium text-[4.167vw] lg:text-[1.25rem] w-full h-[3.75rem]">
            {t("programs.journey.button")}
          </button>
        </a>
      </div>
    </div>
  );
}

export default ProgramSectionJourneyM;
