import logoHRC from "../svg-assets/LogoHRC.png";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="bg-[#000000] py-12">
      {/* <div className="container mx-auto px-2 text-white">
                <div className="flex justify-between">
                    <div>
                        <h2 className="text-3xl font-bold mb-4">Explore</h2>
                        <ul className="flex space-x-2">
                            <li>LINK 1</li>
                            <li>LINK 2</li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="text-3xl font-bold mb-4">Learn More</h2>
                        <ul className="ml-6">
                            <li>Home</li>
                            <li>Course</li>
                            <li>Blog</li>
                            <li>About</li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="text-3xl font-bold mb-4">HRCFA</h2>
                        <p>All Rights Reserved.</p>
                    </div>
                </div>
            </div> */}
      <div className="">
        <img
          className="pl-8 lg:pl-20 h-auto w-[12.825rem] lg:w-[17rem]"
          src={logoHRC}
          alt="hrcfa-logo"
        />
        <div className="flex flex-row justify-center lg:px-[3rem]">
          <div className="pl-[2.625rem] mt-[0.658rem] font-inter text-[3.333vw] lg:text-[1rem] basis-1/2 lg:basis-1/3">
            <p className="font-inter text-[3.333vw] lg:text-[1rem]">
              highreachingclub@gmail.com
            </p>
            <p>+62 882 1010 0799</p>
            <p className="mt-[0.875rem]">
            Rukan Eksklusif Bukit Golf Mediterania Blok D No 25 Pantai Indah Kapuk (PIK, Kapuk Muara, Kec. Penjaringan, Jkt Utara, Daerah Khusus Ibukota Jakarta 14470)
            </p>
          </div>
          <div className="hidden lg:basis-1/3 lg:inline-block pl-[7rem] lg:mr-12">
            <p className="mt-[1.563rem] font-inter font-bold text-[3.333vw] lg:text-[1rem]">
              {t("footer.find_us")}
            </p>
            <div className="mt-[1rem] flex flex-row gap-[1.625rem] text-[5vw] lg:text-[1.5rem]">
              <a href="https://wa.me/6288210100799" target="_blank">
                <FaWhatsapp />
              </a>
              <a href="https://www.instagram.com/hrc.fa/" target="_blank">
                <FaInstagram />
              </a>
              <a href="https://www.tiktok.com/@hrc.fa" target="_blank">
                <FaTiktok />
              </a>
              <a
                href="https://www.youtube.com/@highreachingclub"
                target="_blank"
              >
                <FaYoutube />
              </a>
              <a
                href="https://www.facebook.com/highreachingclub"
                target="_blank"
              >
                <FaFacebookF />
              </a>
              <a href="https://www.linkedin.com/company/hrcfa" target="_blank">
                <FaLinkedin />
              </a>
            </div>
          </div>
          <div className="basis-1/2 lg:basis-1/3 flex justify-center inline-block">
            <a
              href="https://www.google.com/maps/place/HRC+FA+-+High+Reaching+Club+Football+Academy/@-6.165137,106.881723,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69fd9b75d3ff1b:0x2ffe202c4e948e1!8m2!3d-6.165137!4d106.8842979!16s%2Fg%2F11r16qqxcs?hl=en&entry=ttu"
              target="_blank"
            >
              <img
                src="https://d3hde7ibm2i7sg.cloudfront.net/Maps.png"
                alt="hrcfa-location"
              />
            </a>
          </div>
        </div>
        <div className="px-[2.625rem] lg:hidden">
          <p className="mt-[1.563rem] font-inter font-bold text-[3.333vw] lg:text-[1rem]">
            {t("footer.find_us")}
          </p>
          <div className="mt-[1rem] flex flex-row gap-[1.625rem] text-[5vw] lg:text-[1.5rem]">
            <a href="https://wa.me/6288210100799" target="_blank">
              <FaWhatsapp />
            </a>
            <a href="https://www.instagram.com/hrc.fa/" target="_blank">
              <FaInstagram />
            </a>
            <a href="https://www.tiktok.com/@hrc.fa" target="_blank">
              <FaTiktok />
            </a>
            <a href="https://www.youtube.com/@highreachingclub" target="_blank">
              <FaYoutube />
            </a>
            <a href="https://www.facebook.com/highreachingclub" target="_blank">
              <FaFacebookF />
            </a>
            <a href="https://www.linkedin.com/company/hrcfa" target="_blank">
              <FaLinkedin />
            </a>
          </div>
        </div>
        <div className="mt-[2.313rem] text-center">
          <p className="font-inter text-[2.5vw] lg:text-[0.875rem]">
            Copyright HRC Football Academy. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
