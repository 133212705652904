import { Trans, useTranslation } from 'react-i18next';
function CareerCenterCTA() {
    const { t } = useTranslation();
    return (
        <div className="text-center lg:mt-[13.4rem]">
            <div className="lg:max-w-[45rem] mx-auto">
            <p className="px-[2.625rem] text-[4.167vw] lg:text-[1.25rem] font-bold font-inter">{t('career_center.cta.header')}</p>
            <p className="mt-[1rem] text-[4.167vw] lg:text-[1.25rem] px-[2.625rem] font-inter">{t('career_center.cta.subheader')}</p>
            </div>
            <div className="px-[2.625rem] w-full mt-[2.563rem] mb-[8.438rem]">
                <a href="https://wa.me/6288210100799" target="_blank">
            <button className="career-cta-button bg-hrcRed lg:max-w-[24.688rem] font-medium text-[4.167vw] lg:text-[1.25rem] w-full h-[3.75rem]">{t('career_center.cta.button')}</button>
            </a>
            </div>
        </div>
    )
}

export default CareerCenterCTA;