import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation, FreeMode } from "swiper/modules";

import placeholderImage from "../../svg-assets/placeholder.jpeg";
import { Trans, useTranslation } from "react-i18next";

function CareerSectionSuccessPlayer() {
  const { t } = useTranslation();
  const reviews = [
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/NIZ6XiG.png",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/BsfFcaG.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/A3DAvyG.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/DCzaj14.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/itWwkkY.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/R07eswU.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/8bWhekP.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/JftS2I7.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/gCBZVNV.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/j8jsbfS.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/1oOHlqb.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/CCIJqmy.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/ksmbtoi.jpg",
    },
    {
      review: "This ball rocks!",
      image: placeholderImage,
      name: "Peter Leonard",
      position: "Jungle Hyper",
      rating: 5,
      bgimage:
        "https://i.imgur.com/9KK1JHV.jpg",
    },
  ];
  return (
    <div className="px-[2.625rem] lg:mt-[14rem] max-w-6xl mx-auto space-y-4 py-12 px-6 text-center">
      <p className="text-[6.667vw] lg:mb-[4.375rem] lg:text-[2rem] font-bold font-poppins">
        {t("career_center.success_story.header")}
      </p>
      <div>
        <Swiper
          navigation={true}
          modules={[Navigation, FreeMode]}
          slidesPerView={1}
          breakpoints={{
            1000: {
              slidesPerView: 3,
              spaceBetween: 15,
              centeredSlides: false,
            },
          }}
          className=""
        >
          {reviews.map((review, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="bg-slate-100 flex flex-col items-center w-full h-auto">
                  <img
                    className="w-full"
                    src={review.bgimage}
                    alt="review-image"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default CareerSectionSuccessPlayer;
