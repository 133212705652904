import { FaEnvelope } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Career Center', href: '/career' },
  { name: 'Programs & Facilities', href: '/programs' },
  { name: 'Find Us On Youtube', href: '/online-course' },
  { name: 'About Us', href: '/about-us' },
  { name: 'Contact Us', href: '/contact-us' }
]

export default function HeroContact() {
  const { t } = useTranslation();

  return (
    // <div className="bg-white">

    //   <div className="relative isolate px-6 pt-14 lg:px-8">
    //     <div
    //       className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    //       aria-hidden="true"
    //     >
    //       <div
    //         className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
    //         style={{
    //           clipPath:
    //             'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    //         }}
    //       />
    //     </div>
    //     <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
    //       <div className="hidden sm:mb-8 sm:flex sm:justify-center">
    //         <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
    //           Announcing our next round of funding.{' '}
    //           <a href="#" className="font-semibold text-indigo-600">
    //             <span className="absolute inset-0" aria-hidden="true" />
    //             Read more <span aria-hidden="true">&rarr;</span>
    //           </a>
    //         </div>
    //       </div>
    //       <div className="text-center">
    //         <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
    //           Data to enrich your online business
    //         </h1>
    //         <p className="mt-6 text-lg leading-8 text-gray-600">
    //           Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
    //           fugiat veniam occaecat fugiat aliqua.
    //         </p>
    //         <div className="mt-10 flex items-center justify-center gap-x-6">
    //           <a
    //             href="#"
    //             className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    //           >
    //             Get started
    //           </a>
    //           <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
    //             Learn more <span aria-hidden="true">→</span>
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //     <div
    //       className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    //       aria-hidden="true"
    //     >
    //       <div
    //         className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
    //         style={{
    //           clipPath:
    //             'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    //         }}
    //       />
    //     </div>
    //   </div>
    // </div>
    <div className="hero-contact-linear-gradient relative h-[100vh]">

<div className="absolute bottom-[2.5rem] rounded-[0.938rem] right-[3.438rem] py-[2rem] px-[1rem] bg-[#1C1C1C] opacity-95">
            <div className="px-[2.625rem] text-left">
            <p className="text-[6.667vw] lg:text-[2rem] font-poppins font-bold">{t('contact.header')}</p>
            </div>
            <div className="mt-[2.688rem] flex flex-row gap-[4.3rem] px-[2.625rem]">
            <div className="flex flex-col">
                <div className="flex flex-row gap-[1.875rem] justify-start items-center">
                <a href="https://wa.me/6288210100799" target="_blank"><FaWhatsapp className="text-[1.813rem]" /></a>
                <p className="text-[4.167vw] lg:text-[1.25rem] font-inter">+62 8821 0100 799</p>
                </div>
                <div className="flex flex-row mt-[2.813rem] gap-[1.875rem] justify-start items-center">
                <a href="https://www.instagram.com/hrc.fa/" target="_blank"><FaInstagram className="text-[1.813rem]" /></a>
                <p className="text-[4.167vw] lg:text-[1.25rem] font-inter">hrc.fa</p>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="flex flex-row mt-[2.813rem] gap-[1.875rem] justify-start items-center">
                <a href="https://www.tiktok.com/@hrc.fa" target="_blank"><FaTiktok className="text-[1.813rem]" /></a>
                <p className="text-[4.167vw] lg:text-[1.25rem] font-inter">hrc.fa</p>
                </div>
                <div className="flex flex-row mt-[2.813rem] gap-[1.875rem] justify-start items-center">
                <a href="https://www.youtube.com/@highreachingclub" target="_blank"><FaYoutube className="text-[1.813rem]" /></a>
                <p className="text-[4.167vw] lg:text-[1.25rem] font-inter">HRC Football Academy</p>
                </div>
            </div>
            </div>
            <div className="px-[2.625rem] w-full mt-[4rem] text-center">
                <a href="https://wa.me/6288210100799" target="_blank">
            <button className="contact-us-socials-button bg-hrcRed lg:max-w-[24.688rem] font-medium text-[4.167vw] lg:text-[1.25rem] w-full h-[3.75rem]">{t('navbar.contact')}</button>
            </a>
            </div>
        </div>
  </div>
)}