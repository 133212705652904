import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import {Link} from 'react-router-dom';
import medioLogo from '../svg-assets/medio-logo.png';
import { Trans, useTranslation } from 'react-i18next';


const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Career Center', href: '/career' },
  { name: 'Programs & Facilities', href: '/programs' },
  { name: 'Find Us On Youtube', href: '/online-course' },
  { name: 'About Us', href: '/about-us' },
  { name: 'Contact Us', href: '/contact-us' }
]

export default function HeroCareerMobile() {
  const { t } = useTranslation();

  return (
    // <div className="bg-white">

    //   <div className="relative isolate px-6 pt-14 lg:px-8">
    //     <div
    //       className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    //       aria-hidden="true"
    //     >
    //       <div
    //         className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
    //         style={{
    //           clipPath:
    //             'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    //         }}
    //       />
    //     </div>
    //     <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
    //       <div className="hidden sm:mb-8 sm:flex sm:justify-center">
    //         <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
    //           Announcing our next round of funding.{' '}
    //           <a href="#" className="font-semibold text-indigo-600">
    //             <span className="absolute inset-0" aria-hidden="true" />
    //             Read more <span aria-hidden="true">&rarr;</span>
    //           </a>
    //         </div>
    //       </div>
    //       <div className="text-center">
    //         <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
    //           Data to enrich your online business
    //         </h1>
    //         <p className="mt-6 text-lg leading-8 text-gray-600">
    //           Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
    //           fugiat veniam occaecat fugiat aliqua.
    //         </p>
    //         <div className="mt-10 flex items-center justify-center gap-x-6">
    //           <a
    //             href="#"
    //             className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    //           >
    //             Get started
    //           </a>
    //           <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
    //             Learn more <span aria-hidden="true">→</span>
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //     <div
    //       className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    //       aria-hidden="true"
    //     >
    //       <div
    //         className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
    //         style={{
    //           clipPath:
    //             'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    //         }}
    //       />
    //     </div>
    //   </div>
    // </div>
    <div className="hero-career-mobile-linear-gradient relative h-[100vh]">

    <div className="absolute bottom-[4.5rem] lg:bottom-[7rem] lg:right-[7rem] isolate lg:px-8">
      <div className="black-overlay-text">
      <img className="px-[2.625rem]" src={medioLogo} />
      <p className="mt-[0.875rem] px-[2.625rem] font-poppins font-bold text-[6.667vw] lg:text-[2rem]">{t('career_center.hero.header')}</p>
      <p className="mt-[0.875rem] px-[2.625rem] font-poppins text-[4.167vw] lg:max-w-[35rem] lg:text-[1.25rem]">{t('career_center.hero.subtitle')}</p>
      </div>
      <div className="text-center lg:text-left px-[2.625rem]">
        <a href="https://youtu.be/xboInZznreY?si=MDxPbgwkPARZ2EgR" target="_blank">
      <button className="w-full lg:max-w-[24.688rem] lg:mt-[5rem] mt-[1.875rem] h-[5rem] bg-hrcRed font-inter rounded-[0.188rem] font-bold text-[4.167vw] lg:text-[1.25rem]">{t('career_center.hero.button')}</button>
      </a>
      </div>
    </div>
  </div>
  )
}