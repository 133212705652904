import { Trans, useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';

function CoachesSectionHomeM() {
    const { t } = useTranslation();
    return (
        <div className="h-[100vh] relative coach-home-coach-mobile-linear-gradient w-full text-center">
                <div className="absolute coach-centering w-[16rem] mx-auto top-[4.125rem] lg:text-center">
            <p className="font-poppins font-bold text-[6.667vw] lg:text-[2rem] lg:text-[2rem]">{t('home.coaches.header')}</p>
            </div>
            <div className="coach-centering-subtitle">
            <p className="mx-[2.625rem] lg:mx-0 font-poppins text-[4.167vw] lg:text-[1.25rem] lg:text-[1.25rem] ">{t('home.coaches.subtitle')}</p>
            <div className="mx-[2.625rem] lg:mx-0">
            <Link to="/about-us"><button className="mt-[2.313rem] lg:max-w-[24.688rem] bg-hrcRed font-inter rounded-[0.188rem] font-medium text-[4.167vw] lg:text-[1.25rem] lg:text-[1.25rem] w-full h-[3.75rem]">{t('home.coaches.button')}</button></Link>
            </div>
            </div>
        </div>
    )
}

export default CoachesSectionHomeM;